<!--
 * @Description: 推荐楼盘设置
 * @Author: 琢磨先生
 * @Date: 2023-10-10 12:10:02
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-10-12 15:58:32
-->
<template>
  <el-card class="box query">
    <el-button type="primary" size="small" icon="setting" round @click="onOpen">设置</el-button>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="dataList" border stripe>
      <el-table-column label="序号" type="index" width="100"></el-table-column>
      <el-table-column label="楼盘名称" prop="name"></el-table-column>
      <el-table-column label="状态" width="200">
        <template #default="scope">
          <el-link type="success" v-if="scope.row.stopStatus==0" :underline="false">正常</el-link>
          <el-link type="danger" v-else :underline="false">{{scope.row.stopStatusText}}</el-link>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-drawer title="设置推荐楼盘" v-model="visibleDrawer" direction="rtl" size="800px">
    <draggable item-key="id" v-model="draggableList">
      <template #item="{ element, index }">
        <div class="draggable_row">
          <div class="name">
            <span>{{ index + 1 }}、{{ element.name }}</span>
            <el-link
              :underline="false"
              :type="element.stopStatus==0?'success':'danger'"
            >({{ element.stopStatusText }})</el-link>
          </div>
          <div class="remove">
            <el-popconfirm width="220" title="您确定要删除？" @confirm="onDelete(element)">
              <template #reference>
                <el-button size="small" type="danger" link icon="delete"></el-button>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </template>
    </draggable>

    <template #footer>
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <el-button type="primary" link round size="small" icon="plus" @click="openAddDialog">添加楼盘</el-button>
        <el-button type="primary" @click="onSubmit" :loading="saving">确 定</el-button>
      </div>
    </template>
  </el-drawer>

  <el-dialog title="添加楼盘" v-model="visibleDialog" draggable width="800PX">
    <el-form :model="query" ref="form" :inline="true">
      <el-form-item label>
        <el-input v-model="query.q" placeholder="楼盘名称搜索" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" round icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="table" :data="tableData.data" row-key="id" style="height: 500px;">
      <el-table-column label type="selection" reserve-selection width="50"></el-table-column>
      <el-table-column label="名称">
        <!-- <template #header>
          <el-form :model="query" ref="form" :inline="true">
            <el-form-item label>
              <el-input v-model="query.q"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearch" round icon="search">查询</el-button>
            </el-form-item>
          </el-form>
        </template>-->
        <template #default="scope">{{ scope.row.name }}</template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-link
            :underline="false"
            :type="scope.row.stopStatus==0?'success':'danger'"
          >({{ scope.row.stopStatusText }})</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>

    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },
  data() {
    return {
      loading: true,
      saving: false,
      visibleDrawer: false,
      visibleDialog: false,
      searchLoading: false,
      dataList: [],
      draggableList: [], //排序的列表
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    this.loadData();
    this.loadSearchData();
  },
  methods: {
    onOpen() {
      this.draggableList = this.dataList.map((o) => {
        return {
          id: o.id,
          name: o.name,
          stopStatus: o.stopStatus,
          stopStatusText: o.stopStatusText,
        };
      });
      this.visibleDrawer = true;
    },
    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("admin/v1/estate/recommend")
        .then((res) => {
          if (res.code == 0) {
            this.dataList = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 删除
     */
    onDelete(row) {
      var i = this.draggableList.findIndex((o) => o.id == row.id);
      this.draggableList.splice(i, 1);
    },

    /**
     * 保存
     */
    onSubmit() {
      this.saving = true;
      var data = this.draggableList.map((o) => o.id);
      this.$http
        .post("admin/v1/estate/recommend/sort", data)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
            this.visibleDrawer = false;
          }
        })
        .finally(() => (this.saving = false));
    },

    /**
     * 打开添加弹框
     */
    openAddDialog() {
      this.visibleDialog = true;
    },

    /**
     * 确定添加
     */
    onConfirm() {
      var rows = this.$refs.table.getSelectionRows();
      rows.forEach((item) => {
        if (this.draggableList.findIndex((o) => o.id == item.id) == -1) {
          this.draggableList.push({
            id: item.id,
            name: item.name,
            stopStatus: item.stopStatus,
            stopStatusText: item.stopStatusText,
          });
        }
      });
      this.visibleDialog = false;
    },

    /**
     * 关键字搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadSearchData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadSearchData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadSearchData();
    },
    /**
     * 加载楼盘数据
     */
    loadSearchData() {
      this.searchLoading = true;
      this.$http
        .post("admin/v1/estate/base", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.searchLoading = false));
    },
  },
};
</script>

<style  scoped>
/* 排序 */
.draggable_row {
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>